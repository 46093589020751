<template>
  <div class="market">
    <div class="title">{{ $t("market[0]") }}</div>
    <div class="bigBox">
      <!-- v-for生成店铺信息 -->
      <div class="thing" v-for="(item, index) in box" :key="index">
        <div class="one">
          <div class="head">{{ $t("market[1]") }}</div>
          <div>
            <p class="more">{{ $t("market[2]") }}</p>
            <div class="word">
              <div>{{ $t("market[3]") }}</div>
              <div>
                <p>{{ $t("market[4]") }}</p>
                <p>{{ $t("market[5]") }}</p>
              </div>
            </div>
            <div class="word2">
              {{ $t("market[6]") }}<span>{{ $t("market[7]") }}</span>
            </div>
          </div>
        </div>
        <div class="two">
          <div class="two_box">
            <div class="more">{{ $t("market[8]") }}</div>
            <p>R$ 0.98</p>
            <button @click="turn()">{{ $t("market[9]") }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <van-dialog
      v-model="Popup"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="bg">
        <div class="subtitle">{{ $t("market[10]") }}</div>
        <div class="content">
          {{ $t("market[11]") }}
        </div>
        <div class="btn">
          <button @click="jump()">{{ $t("market[12]") }}</button>
          <button @click="down()">{{ $t("market[13]") }}</button>
        </div>
        <img
          @click="down()"
          src="../../../public/static/icon2/delete.png"
          alt=""
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Popup: false,
      box: [
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
        { src: require("../../../public/static/icon/rectangle.png") },
      ],
    };
  },
  methods: {
    turn() {
      this.Popup = true;
    },
    down() {
      this.Popup = false;
    },
    jump() {
      this.$router.push("/user/details");
    },
  },
};
</script>
<style scoped>
.market {
  overflow: auto;
  height: 100%;
}
.title {
  color: #fff;
  width: 100%;
  height: 50px;
  font-size: 18px;
  background: #4e51bf;
  position: fixed;
  top: 0px;
  text-align: center;
  line-height: 40px;
}
.bigBox {
  margin-top: 50px;
  padding: 15px;
}
.thing {
  border: 1px solid #dbdbdbdb;
  display: flex;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: #dbdbdbdb 0px 0px 8px 1px;
}
.one {
  width: 72%;
  display: flex;
  padding-bottom: 10px;
}
/* .one img {
  width: 80px;
  height: 80px;
  margin: 15px;
  border-radius: 10px;
} */
.head {
  width: 70px;
  height: 70px;
  margin: 30px 10px 0 10px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #b3b5fc, #fce7d0);
  text-align: center;
  color: #4e51bf;
  font-weight: 600;
  line-height: 70px;
  flex-shrink: 0;
}
.more {
  color: #4e51bf;
  font-size: 16px;
  margin-top: 15px;
  line-height: 30px;
  font-weight: 600;
}
.word {
  display: flex;
  font-size: 13px;
}
.word2 {
  font-size: 13px;
}
.word2 span {
  color: #ff93a4;
  font-size: 17px;
  font-weight: 600;
}
.two {
  /* width: 100px; */
}
.two_box {
  padding-left: 10px;
  border-left: 1px solid #dbdbdbdb;
}
.two_box p {
  color: #ff93a4;
  font-weight: 600;
}
.two_box button {
  background: #4e51bf;
  color: #fff;
  width: 70px;
  height: 22px;
  border-radius: 10px;
  border: 1px solid #a571b2;
  margin: auto;
  margin-top: 18px;
  margin-left: 5px;
  font-size: 12px;
  box-shadow: #e087a9 0px -2px 1px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-dialog {
  margin: 2px;
  background-image: linear-gradient(to bottom, #ff92a4, #5052bf);
  padding: 7px;
  border-radius: 5px;
  overflow: initial;
}
.bg {
  background-color: #fff;
}
.subtitle {
  color: #fe889d;
  font-size: 18px;
  text-align: center;
  padding-top: 40px;
  letter-spacing: 2px;
}
.content {
  margin: 20px 45px 10px 45px;
  font-size: 13px;
  line-height: 25px;
  font-family: Alibaba PuHuiTiR;
  font-weight: 600;
}
.btn {
  display: flex;
  justify-content: space-around;
}
.bg button {
  background: #4e51bf;
  border-radius: 15px;
  width: 140px;
  height: 40px;
  border: 1px solid #e087a9;
  margin-bottom: 35px;
  margin-top: 20px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bg img {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 105%;
  left: 146px;
}
</style>